import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

import OrderContainer from '../../common/OrderContainer';
import routesMap from '../../../Routes';
import Step6 from '../../../components/Step6v1';
import callApi from '../../../services/api';
import { pricingInverse, metadata } from '../../../services/pricing';
import { registerCard } from '../../../services/mangopay';
import { trackEvent, trackUser } from '../../../services/analytics';
import featureSwitch from '../../../services/featureSwitch';
import { getTotalClothes, getTotalPaid, getTotalPaidBrand, getMinOrderAmountFee }
  from '../../../core/services/orderPrice';
import { getSlots } from '../orderReducer';

function trackOrderForFacebook(total) {
  if (typeof fbq === 'function') {
    // eslint-disable-next-line no-undef
    fbq('track', 'Purchase', { value: total, currency: 'EUR' });
  } else if (typeof Raven !== 'undefined') {
    Raven.captureException('fbq tracking is undefined'); // eslint-disable-line
  } else {
    console.error('fbq tracking is undefined');
  }
}

class InfosContainer extends Component {
  constructor() {
    super();
    this.state = { deliveryFee: metadata.deliveryFee };
    this.submitRegisterAndOrder = this.submitRegisterAndOrder.bind(this);
    this.submitLoggedInOrder = this.submitLoggedInOrder.bind(this);
    this.checkPromoCode = this.checkPromoCode.bind(this);
    this.removePromoCode = this.removePromoCode.bind(this);
    this.hideError = this.hideError.bind(this);
    this.setDeliveryFee = this.setDeliveryFee.bind(this);
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    if (window.location.hostname !== 'localhost') {
      window.onbeforeunload = function () {
        return true;
      };
    }
    const { referrer } = this.props;
    if (referrer && referrer.referrerCode) {
      this.checkPromoCode(referrer.referrerCode);
    }
  }

  setDeliveryFee(deliveryFee) {
    this.setState({ deliveryFee });
  }

  hideError() {
    this.setState({ error: false });
  }

  createRequest(customer, cardData = {}, seller) {
    const order = {
      rdv1Slots: this.props.slots.map(slot => ({ begin: slot.begin.toDate(), end: slot.end.toDate() })),
      fabrics: this.props.fabrics,
      threadColor: this.props.threadColor,
      minOrderAmount: featureSwitch('step6_noMinOrderAmount') ? 12 : metadata.minOrderAmount,
      deliveryFee: this.state.deliveryFee,
      pricingVersion: metadata.version,
      clothes: this.props.clothes.map(cloth => ({
        ...cloth,
        brand: !!process.env.GATSBY_BRAND,
        items: cloth.alterations ? Object.keys(cloth.alterations).map(alterationId => ({
          id: alterationId,
          name: (pricingInverse[alterationId].variants &&
            pricingInverse[alterationId].variants[process.env.GATSBY_BRAND] &&
            pricingInverse[alterationId].variants[process.env.GATSBY_BRAND].name),
          quantity: cloth.alterations[alterationId],
          price: (pricingInverse[alterationId].variants &&
            pricingInverse[alterationId].variants[process.env.GATSBY_BRAND] &&
            pricingInverse[alterationId].variants[process.env.GATSBY_BRAND].price) ||
            pricingInverse[alterationId].price,
          // TODO add unit lining price ??
        })) : [],
      })),
      promoCode: this.state.promoCode && this.state.promoCode.code,
      discounts: this.state.promoCode && this.state.promoCode.code ? [{
        ...this.state.promoCode,
        brand: undefined,
      }] : [],
      brand: process.env.GATSBY_BRAND,
      cardToken: cardData.cardId || (cardData.cardNumber && '1'),
      seller,
    };
    return { customer, order };
  }

  redirect3DS(redirectUrl, total) {
    if (redirectUrl) {
      trackEvent('order', '3Ds_redirect', 'basket', total);
      trackOrderForFacebook(total, this.state.promoCode);
      this.setState({ redirecting: true });
      window.onbeforeunload = null;
      window.location = redirectUrl;
    } else {
      this.redirectToSuccess(total);
    }
  }

  redirectToSuccess(total) {
    window.onbeforeunload = null;
    trackEvent('order', 'redirectToSuccess', 'basket', total);
    trackOrderForFacebook(total, this.state.promoCode);
    navigate(this.props.nextStep || routesMap.Success.url);
  }

  submitCard(cardData, order, customer) {
    this.props.dispatch({ type: 'ORDER.SET_SUBMITTED', order });
    if (cardData.cardId || !cardData.cardNumber) {
      return this.redirectToSuccess(getTotalPaid(order));
    }
    return registerCard(cardData, customer._id) // eslint-disable-line no-underscore-dangle
      .then(card =>
        callApi('public/submitCard', 'post',
          { card, orderId: order._id, brand: process.env.GATSBY_BRAND })) // eslint-disable-line no-underscore-dangle
      .then((res) => {
        if (featureSwitch('step6_skip3ds')) {
          return this.redirectToSuccess(getTotalPaid(order));
        }
        return this.redirect3DS(res.redirectUrl, getTotalPaid(order));
      })
      .catch((response) => {
        this.setState({ error: true, isLoading: false, errorType: response.error });
        trackEvent('erreur', response.error);
        if (typeof Raven !== 'undefined') {
          Raven.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          console.error(response);
        }
      });
  }

  submitRegisterAndOrder(customerReq, cardData, seller) {
    this.setState({ isLoading: true });
    callApi('registerAndOrder', 'post', this.createRequest(customerReq, cardData, seller))
      .then(({ order, customer }) => {
        this.props.dispatch({ type: 'CUSTOMER.SET_CUSTOMER', customer });
        trackUser(customer);
        if (featureSwitch('step6_allowOrderWithoutCard') && !(cardData.cardNumber || cardData.cardNumber)) {
          this.redirectToSuccess(getTotalPaid(order));
        } else {
          this.submitCard(cardData, order, customer);
        }
      })
      .catch((response) => {
        this.setState({ error: true, isLoading: false, errorType: response.error });
        trackEvent('erreur', 'echec-soumission-commande-non-connecte');
        if (typeof Raven !== 'undefined') {
          Raven.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          console.error(response);
        }
      });
  }

  checkPromoCode(promoCode) {
    this.setState({ isPromoCodeLoading: true });
    callApi(`public/checkPromoCode/${promoCode}`)
      .then((res) => {
        trackEvent('ajout-code-promo', promoCode);
        this.setState({ promoCode: res.promoCode, errorPromoCode: false, isPromoCodeLoading: false });
      })
      .catch(() => {
        trackEvent('ajout-code-promo-echec', promoCode);
        this.setState({ promoCode: null, errorPromoCode: true, isPromoCodeLoading: false });
      });
  }

  removePromoCode() {
    this.setState({ promoCode: null, errorPromoCode: true, isPromoCodeLoading: false });
  }

  submitLoggedInOrder(customerReq, cardData, seller) {
    this.setState({ isLoading: true });
    callApi('editCustomerAndOrder', 'post', this.createRequest(customerReq, cardData, seller))
      .then(({ order, customer }) => this.submitCard(cardData, order, customer))
      .catch((response) => {
        trackEvent('erreur', 'echec-soumission-commande-connecte');
        this.setState({ error: true, isLoading: false, errorType: response.error });
        if (typeof Raven !== 'undefined') {
          Raven.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          console.error(response);
        }
      });
  }

  render() {
    const order = this.createRequest().order;
    const total = getTotalClothes(order);
    const totalPaid = getTotalPaid(order);
    const totalPaidBrand = getTotalPaidBrand(order);
    const minOrderAmountFee = getMinOrderAmountFee(order);
    const minOrderAmount = featureSwitch('step6_noMinOrderAmount') ? 12 : metadata.minOrderAmount;
    return (
      <OrderContainer>
        <Step6
          checkPromoCode={this.checkPromoCode}
          removePromoCode={this.removePromoCode}
          customer={this.props.customer}
          error={this.state.error}
          errorType={this.state.errorType}
          errorPromoCode={this.state.errorPromoCode}
          isLoading={this.state.isLoading}
          isLoggued={!!this.props.customer}
          isPromoCodeLoading={this.state.isPromoCodeLoading}
          hideError={this.hideError}
          promoCode={this.state.promoCode}
          slots={this.props.slots}
          submitLoggedInOrder={this.submitLoggedInOrder}
          submitRegisterAndOrder={this.submitRegisterAndOrder}
          successLoggedInOrder={this.state.successLoggedInOrder}
          successRegisterOrder={this.state.successRegisterOrder}
          total={total}
          totalPaid={totalPaid}
          totalPaidBrand={totalPaidBrand}
          deliveryFee={this.state.deliveryFee}
          setDeliveryFee={this.setDeliveryFee}
          minOrderAmount={minOrderAmount}
          minOrderAmountFee={minOrderAmountFee}
        />
      </OrderContainer>
    );
  }
}

InfosContainer.propTypes = {
  clothes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fabrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  threadColor: PropTypes.string,
  customer: PropTypes.shape({}),
  referrer: PropTypes.shape({}),
  dispatch: PropTypes.func.isRequired,
  nextStep: PropTypes.string.isRequired,
  slots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

InfosContainer.defaultProps = {
  customer: {},
  referrer: null,
  threadColor: null,
};

const mapStateToProps = state => ({
  slots: getSlots(state),
  clothes: state.order.clothes,
  fabrics: state.order.fabrics,
  customer: state.customer.customer,
  referrer: state.order.referrer,
  threadColor: state.order.threadColor,
});
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(InfosContainer);
