import React from 'react';
import InfosContainer from '../containers/InfosContainer';
import routes from '../../../Routes';
import Layout from '../../../layouts/index';

const nextStep = routes.EstimationVariantSuccess.url;
const Step6Infos = () => (
  <Layout slug="EstimationVariantInfos">
    <InfosContainer nextStep={nextStep} />;
  </Layout>
);
export default Step6Infos;
