import moment from 'moment-timezone';
import { pricingInverse } from '../../services/pricing';
import { reindexClothesName } from '../../core/services/order';
import featureSwitch from '../../services/featureSwitch';
import simpleAlterations from '../../services/simpleAlterations';

moment.tz.setDefault('Europe/Paris');

// Initial State
const initialState = {
  clothes: [],
  total: 0,
  slots: [],
  fabrics: [],
  showAllSlots: false,
  threadColor: '',
};
const initialClothState = { liningsNumber: 0, clothType: 'SIMPLE' };

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ORDER.ADD_CLOTH': {
      const newCloth = { ...initialClothState, ...action.cloth };
      return { ...state, clothes: reindexClothesName([...state.clothes, newCloth]) };
    }

    case 'ORDER.SET_CLOTH_TYPE': {
      const { clothIndex } = action;
      const newCloth = { ...state.clothes[clothIndex] };
      if (newCloth.clothType === action.clothType) {
        return state;
      }
      newCloth.alterations = {};
      newCloth.clothType = action.clothType;
      const newClothes = [...state.clothes];
      newClothes[clothIndex] = newCloth;
      return { ...state, clothes: newClothes };
    }

    case 'ORDER.ADD_LINING': {
      const { clothIndex } = action;
      const newCloth = { ...state.clothes[clothIndex] };
      newCloth.liningsNumber = Math.min(newCloth.liningsNumber + 1, 99);
      const newClothes = [...state.clothes];
      newClothes[clothIndex] = newCloth;
      return { ...state, clothes: newClothes };
    }

    case 'ORDER.REMOVE_LINING': {
      const { clothIndex } = action;
      const newCloth = { ...state.clothes[clothIndex] };
      newCloth.liningsNumber = Math.max(newCloth.liningsNumber - 1, 0);
      const newClothes = [...state.clothes];
      newClothes[clothIndex] = newCloth;
      return { ...state, clothes: newClothes };
    }

    case 'ORDER.TOGGLE_ALTERATION': {
      const { clothIndex, alterationId } = action;
      const newCloth = { ...state.clothes[clothIndex] };
      newCloth.alterations = newCloth.alterations || {};
      newCloth.alterations[alterationId] = newCloth.alterations[alterationId] > 0 ? 0 : 1;
      const newClothes = [...state.clothes];
      newClothes[clothIndex] = newCloth;
      return { ...state, clothes: newClothes };
    }

    case 'ORDER.SET_ALTERATION_QUANITY': {
      const { clothIndex, alterationId } = action;
      const newCloth = { ...state.clothes[clothIndex] };
      newCloth.alterations = newCloth.alterations || {};
      newCloth.alterations[alterationId] = (action.quantity < 0) ? 0 : action.quantity;
      const newClothes = [...state.clothes];
      newClothes[clothIndex] = newCloth;
      return { ...state, clothes: newClothes };
    }

    case 'ORDER.REMOVE_CLOTH_BY_SLUG': {
      const clothesWithoutSlug = state.clothes.filter(cloth => cloth.slug !== action.slug);
      const clothesWithSlug = state.clothes.filter(cloth => cloth.slug === action.slug);
      clothesWithSlug.pop();
      return {
        ...state,
        clothes: reindexClothesName([...clothesWithoutSlug, ...clothesWithSlug]),
      };
    }

    case 'ORDER.DELETE_CLOTH':
      return {
        ...state,
        clothes: reindexClothesName(state.clothes.filter((c, index) => index !== action.clothIndex)),
      };

    case 'ORDER.SET_SLOTS':
      return { ...state, slots: action.slots };

    case 'ORDER.SELECT_NONE_FABRIC':
      return { ...state, fabrics: [] };

    case 'ORDER.TOGGLE_FABRIC': {
      if (state.fabrics.includes(action.fabric)) {
        return { ...state, fabrics: state.fabrics.filter(fabric => fabric !== action.fabric) };
      }
      return { ...state, fabrics: [...state.fabrics, action.fabric] };
    }

    case 'ORDER.SET_REFERRER':
      return { ...state, referrer: action.referrer };

    case 'ORDER.SET_THREAD_COLOR':
      return { ...state, threadColor: action.threadColor };

    case 'ORDER.SET_SUBMITTED':
      return { ...state, submittedOrder: action.order };

    case 'ORDER.INIT':
      return { ...initialState };

    default:
      return state;
  }
};

/* Selectors */

// Get all orders
export const getClothes = state => state.order.clothes;

export const getTotal = state =>
  state.order.clothes.reduce((total, cloth) => {
    const alterationsKeys = Object.keys(cloth.alterations || {});

    return total + alterationsKeys.reduce((sum, alterationId) => {
      if (featureSwitch('alterations_showSimpleAsOffered') && simpleAlterations.includes(alterationId)) {
        return sum;
      }
      const item = pricingInverse[alterationId];
      const brandVariant = item.variants && item.variants[process.env.GATSBY_BRAND];
      const itemPrice = (brandVariant ? brandVariant.price : item.price) || 0;
      const liningUnitPrice = item.liningUnitPrice || 0;
      const quantity = cloth.alterations[alterationId];
      return sum + (quantity * (itemPrice + (cloth.liningsNumber * liningUnitPrice)));
    }, 0);
  }, 0);

export const getShowAllSlots = state => state.order.showAllSlots;
export const getSlot = state => state.order.rdv;
export const getSlots = state => state.order.slots || [];

export const getSubmittedOrder = state => state.order.submittedOrder;

// TODO: move in the right file ?
export const formatCloth = cloth => ({
  ...cloth,
  items: cloth.alterations ? Object.keys(cloth.alterations).map(alterationId => ({
    id: alterationId,
    name: (pricingInverse[alterationId].variants &&
      pricingInverse[alterationId].variants[process.env.GATSBY_BRAND] &&
      pricingInverse[alterationId].variants[process.env.GATSBY_BRAND].name),
    quantity: cloth.alterations[alterationId],
    price: (pricingInverse[alterationId].variants &&
      pricingInverse[alterationId].variants[process.env.GATSBY_BRAND] &&
      pricingInverse[alterationId].variants[process.env.GATSBY_BRAND].price) ||
      pricingInverse[alterationId].price,
    liningUnitPrice: (pricingInverse[alterationId].variants &&
      pricingInverse[alterationId].variants[process.env.GATSBY_BRAND] &&
      pricingInverse[alterationId].variants[process.env.GATSBY_BRAND].liningUnitPrice) ||
      pricingInverse[alterationId].liningUnitPrice,
  })) : [],
});

// Export Reducer
export default OrderReducer;
