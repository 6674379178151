const brandsFeatures = {
  GALERIES_LAFAYETTE: {
    home_showTutorial: true,
    clothesSelector_hideDeco: true,
    clothesSelector_hideMari: true,
    infos_hideCardBlock: true,
    infos_codePromoButtons: [
      { code: 'GALLAF1903128', value: 8 },
      { code: 'GALLAF19031220', value: 20 },
      { code: 'GALLAF19031232', value: 32 },
    ],
    step6_showPromoCodeField: false,
    step6_noMinOrderAmount: true,
    alterations_hideSkipOption: true,
    alterations_hideEmbroidery: true,
    step3_noCurtains: true,
  },
  CASABLANCA: {
    clothesSelector_hideDeco: true,
    clothesSelector_hideMari: true,
    infos_hideCardBlock: true,
    step6_noMinOrderAmount: true,
    step6_showPromoCodeField: true,
    alterations_hideSkipOption: true,
    alterations_hideEmbroidery: true,
    step3_noCurtains: true,
  },
  '85_DEGRES': {
    clothesSelector_hideDeco: true,
    clothesSelector_hideMari: true,
    infos_hideCardBlock: true,
    step6_noMinOrderAmount: true,
    step6_showPromoCodeField: true,
    alterations_hideSkipOption: true,
    alterations_hideEmbroidery: true,
    step3_noCurtains: true,
  },
  THE_KOOPLES: {
    clothesSelector_hideDeco: true,
    clothesSelector_hideMari: true,
    infos_hideCardBlock: true,
    infos_codePromoButton: 'THEKOOPLES_PLATFORM_100_1812',
    step6_showPromoCodeField: false,
    step6_noMinOrderAmount: true,
    alterations_hideSkipOption: true,
    alterations_hideEmbroidery: true,
    step3_noCurtains: true,
  },
  APC: {
    clothesSelector_hideDeco: true,
    step6_noMinOrderAmount: true,
  },
  SEZANE: {
    clothesSelector_hideDeco: true,
    clothesSelector_hideMari: true,
    order_thirdPersonLabels: false,
    progressBar_hide: true,
    alterations_hideSkipOption: true,
    alterations_showSimpleAsOffered: true,
    fabrics_skip: true,
    infos_hideCardBlock: true,
    infos_hideOrderSummary: true,
    infos_validateOrderWording: true,
    success_onTheSpotEvent: true,
  },
  ISABEL_MARANT: {
    clothesSelector_hideDeco: true,
  },
  BOURRIENNE: {
    clothesSelector_hideDeco: true,
  },
  CAROLINA_RITZLER: {
    clothesSelector_hideDeco: true,
  },
  MADURA: {
    home_hideHeaderTitle: true,
    step1_skipAndAdd1DecoCloth: true,
    step2_showTitleWithoutCloth: true,
    step2_renderClothList: false,
    step2_noLining: true,
    step2_openCurtainCategory: true,
    step3_curtainsOnly: true,
    step4_showCurtainWarning: true,
    step6_showPromoCodeField: false,
  },
  brandsDefault: {
    home_hideBeTilliste: true,
    home_showFullPage: false,
    home_showBrandFooter: true,
    order_thirdPersonLabels: true,
    step1_hideFastVariant: true,
    step1_skipModal: false,
    step5_skip: true,
    infos_hideCardBlockOnlyWhenNewCustomer: false,
    step6_allowShadowAccount: true,
    step6_allowOrderWithoutCard: true,
    step6_skip3ds: true,
    clothesSelector_hideMari: false,
  },
  default: {
    // TODO: write description for each feature
    // TODO: do not use numbers for steps but their title
    home_hideBeTilliste: false, // hide the BeTilliste link in menu bar
    home_showFullPage: true,
    home_showBrandFooter: false,
    home_hideHeaderTitle: false,
    home_showTutorial: false,
    order_thirdPersonLabels: false, // use "Le prénom de votre client" instead of "Votre prénom"
    progressBar_hide: false,
    step1_skipModal: true, // modal to offer fast order process => go directly to step 3
    step1_skipAndAdd1DecoCloth: false,
    step1_hideFastVariant: false,
    clothesSelector_hideDeco: false,
    clothesSelector_hideMari: false,
    alterations_hideSkipOption: false,
    alterations_hidePrice: false,
    alterations_showSimpleAsOffered: false,
    alterations_hideEmbroidery: false,
    step2_showTitleWithoutCloth: false,
    step2_renderClothList: true,
    step2_noLining: false,
    step2_openCurtainCategory: false,
    step3_curtainsOnly: false,
    step3_noCurtains: false,
    fabrics_skip: false,
    step4_showCurtainWarning: false,
    step5_skip: false,
    step6_allowShadowAccount: false,
    step6_allowOrderWithoutCard: false,
    step6_showPromoCodeField: true,
    step6_skip3ds: false,
    step6_noMinOrderAmount: false,
    infos_hideCardBlock: false,
    infos_codePromoButtons: false,
    infos_hideCardBlockOnlyWhenNewCustomer: true,
    infos_hideOrderSummary: false,
    infos_validateOrderWording: false,
    infos_code_promo_button: false,
    success_onTheSpotEvent: false,
  },
};

const acceptedTypes = ['boolean', 'string', 'object'];

// TODO: write test to ensure each feature for a brand has a default value
export default function featureSwitch(featureName) {
  // !brand => tilli website (default)
  const brand = process.env.GATSBY_BRAND || 'default';

  if (acceptedTypes.includes(typeof brandsFeatures[brand][featureName])) {
    return brandsFeatures[brand][featureName];
  }

  if (acceptedTypes.includes(typeof brandsFeatures.brandsDefault[featureName])) {
    return brandsFeatures.brandsDefault[featureName];
  }
  return brandsFeatures.default[featureName];
}
