export function hasBrandPromoCode(order) {
  return !!order.discounts && !!order.discounts.find(discount => !!discount.brand);
}

export function getReferrer(order) {
  const discountReferrer = order.discounts && order.discounts.find(discount => discount.referrer);
  return discountReferrer ? discountReferrer.referrer : null;
}

// Precondition need to be hydrated with customer
export function getAddress(order) {
  if (!order || !order.customer || typeof order.customer !== 'object') {
    return null;
  }
  if (typeof order.address !== 'object') {
    return order.customer.address;
  }
  if (typeof order.customer.addresses !== 'object') {
    return null;
  }
  if (!order.address) {
    return null;
  }

  return order.customer.addresses.find(address => order.address.equals(address._id));
}

export const reindexClothesName = clothes =>
  clothes.map((cloth, index) => {
    const clothNumber = clothes
      .filter((clothIt, indexIt) => clothIt.slug === cloth.slug && index >= indexIt)
      .length;

    const clothName = cloth.name.replace(/\d/, '').replace('/', '').replace(/\d/, '').trim();
    const clothTotalNumber = clothes.filter(clothIt => clothIt.slug === cloth.slug).length

    return {
      ...cloth,
      clothNumber,
      clothName,
      name: `${clothName} ${clothNumber}/${clothTotalNumber}`,
    };
  });

export function getNextRdv(order) {
  if (!order || !order.state) return undefined;
  switch (order.state) {
    case 'ACTIVATION':
    case 'WAITING_COUTURIER_ANSWER':
    case 'WAITING_COUTURIER_ANSWER_CHANGE_RDV1':
    case 'COUTURIER_REFUSAL':
    case 'ORDER_CONFIRMATION':
    case 'RDV_1':
      return { type: 'RDV_1', date: order.rdv1 && order.rdv1.begin };
    case 'WAITING_PAYMENT_CUSTOMER':
    case 'WAITING_PAYMENT_BRAND':
    case 'ONGOING_MISSION':
    case 'DELIVERY_PREPARATION':
    case 'RDV_2': {
      if (order.rdv2 && order.rdv2.begin) {
        return { type: 'RDV_2', date: order.rdv2.begin };
      }
      return undefined;
    }
    case 'ADJUSTMENT':
    case 'RDV_3':
      return { type: 'RDV_3', date: order.rdv3 && order.rdv3.begin };
    case 'WAITING_PAYMENT_COUTURIER':
    case 'DONE':
    default:
      return undefined;
  }
}

export function hasCurtains(order) {
  const curtainsInClothes = order.clothes &&
    order.clothes.reduce((hasCurtainsIt, cloth) => hasCurtainsIt || (cloth.slug === 'deco'), false);
  const curtainsFabrics = ['curtain', 'netCurtain', 'heavyCurtain'];
  const curtainsInFabrics = order.fabrics &&
    order.fabrics.reduce((hasCurtainsIt, fabric) => hasCurtainsIt || curtainsFabrics.includes(fabric), false);
  return curtainsInClothes || curtainsInFabrics;
}

// clothes, by opposition to curtains
// TODO: find better wording to distinguish orders.clothes = clothes + curtains
export function hasClothes(order) {
  return order.clothes &&
    order.clothes.reduce((hasClothesIt, cloth) => hasClothesIt || (cloth.slug !== 'deco'), false);
}
