import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '../Home/v2/StyledComponents';
import { colors, margins, fontSizes, mobileThresholdPixels, Total, TotalRow, Highlight }
  from '../styledComponents';
import featureSwitch from '../../services/featureSwitch';

const Container = styled.div`
  padding: 0px;
  margin: ${margins.s} ${margins.m};
  border: ${colors.navy} 1px solid;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s} 0px;
  }
`;

const Title = styled.h4`
  margin: ${margins.s};
  text-align: center;
  font-size: ${fontSizes.l};
  color: ${colors.navy};
  font-weight: 300;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s} ${margins.xs} ${margins.xs};
    padding: ${margins.s} 0 0 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${margins.m};
`;

const ButtonContainer2 = styled.div`
  margin: ${margins.m} ${margins.s};
  display: initial;
`;

const SlotsContainer = styled.div`
  margin: ${margins.m};
`;

const ButtonRow = styled.div`
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin: ${margins.m} 0px;
`;

const OrderSummary = ({ promoCode, deliveryFee, total, totalPaid,
  children, slots, addPromoCode, removePromoCode,
}) => (
  <div>
    {featureSwitch('infos_codePromoButton') &&
      <div>
        {!(promoCode && promoCode.value) ?
          <ButtonContainer>
            <Button
              secondary
              onClick={() => addPromoCode(featureSwitch('infos_codePromoButton'))}
            >100% offerts par The Kooples</Button>
          </ButtonContainer>
          :
          <ButtonContainer>
            <Button
              secondary
              onClick={() => removePromoCode()}
            >à la charge du client</Button>
          </ButtonContainer>
        }
      </div>
    }
    {featureSwitch('infos_codePromoButtons') &&
      <ButtonRow>
        {featureSwitch('infos_codePromoButtons').map((promoCodeIt) => {
          const isSelected = promoCode && promoCode.value === promoCodeIt.value;
          return (
            <ButtonContainer2>
              <Button
                small
                navy={isSelected}
                onClick={() => isSelected ? removePromoCode() : addPromoCode(promoCodeIt.code)}
              >Remise de {promoCodeIt.value}€</Button>
            </ButtonContainer2>
          );
        })}
      </ButtonRow>
    }
    <Container>
      <Title>Les retouches de votre client</Title>
      <Total>
        {promoCode && promoCode.value && featureSwitch('infos_codePromoButton') &&
          <TotalRow bold>
          Prise en charge The Kooples : {promoCode.value}{promoCode.unit === 'EUROS' ? '€' : '%'}
          </TotalRow>
        }
        {featureSwitch('order_thirdPersonLabels') ?
          'Les disponibilités de mon client :' :
          'Vos disponibilités :'
        }
        <SlotsContainer>
          {slots.map((slot) => {
            const rdvDate = slot.begin.format('D MMMM');
            const rdvBegin = slot.begin.format('H[h]mm');
            const rdvEnd = slot.end.format('H[h]mm');
            return (
              <Highlight>
                {`le ${rdvDate} entre ${rdvBegin} et ${rdvEnd}`}<br />
              </Highlight>
            );
          })}
        </SlotsContainer>
        {(total > 0) ?
          <TotalRow>Total des retouches : {total}€</TotalRow> :
          <TotalRow>Total des retouches : devis sur place</TotalRow>
        }
        {promoCode && promoCode.value && !featureSwitch('infos_codePromoButton') &&
          <TotalRow>
            Code promo : -{promoCode.value}{promoCode.unit === 'EUROS' ? '€' : '%'}
          </TotalRow>
        }
        <TotalRow>Frais de déplacement : {deliveryFee}€</TotalRow>
        <TotalRow bold>Montant total : {totalPaid}€</TotalRow>

        {children}
      </Total>
    </Container>
  </div>
);

OrderSummary.propTypes = {
  promoCode: PropTypes.shape({}),
  deliveryFee: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  totalPaid: PropTypes.number.isRequired,
  children: PropTypes.node,
  slots: PropTypes.arrayOf(PropTypes.shape({})),
  addPromoCode: PropTypes.func,
  removePromoCode: PropTypes.func,
};

OrderSummary.defaultProps = {
  promoCode: null,
  children: null,
  slots: [],
  addPromoCode() {},
  removePromoCode() {},
};

export default OrderSummary;
