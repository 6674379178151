import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, Form1, Title4Top, Input, Center, InputRow, Choice, ErrorText, Highlight } from './styledComponents';
import CreditCard from './CreditCard';

import PromoCodeField from './order/PromoCodeField';
import OrderSummary from './order/OrderSummary';
import OrderFooter from './order/OrderFooter';

import checkZipcodeError, { getLocality, getDeliveryFees } from '../services/zipcode';
import { isCardTypeValid, isCardNumberValid } from '../services/mangopay';
import featureSwitch from '../services/featureSwitch';
import { trackUserEmail } from '../services/analytics';

import VisaIcon from '../assets/visa.png';
import MasterCardIcon from '../assets/master-card.png';

const P = styled.p`
  text-align: center;
  color: ${colors.navy};
`;

const CardIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
`;

class InfosBlock extends React.Component {
  constructor(props) {
    super(props);
    const { firstname, lastname, password, email, phone, address, addresses = [], cards = [] }
      = props.customer || {};
    const { street, zipcode, locality, comment } = address || addresses[0] || {};
    const newAccount = !(props.customer && props.customer.firstname);
    const cardId = cards[0] && cards[0].id;
    const customerState = { firstname, lastname, password, email, phone, street, zipcode, locality, comment };
    this.state = { ...customerState, newAccount, cardId, deliveryFee: 5 };

    this.inputChange = this.inputChange.bind(this);
    this.submitRegisterAndOrder = this.submitRegisterAndOrder.bind(this);
    this.submitLoggedInOrder = this.submitLoggedInOrder.bind(this);
    this.submit = this.submit.bind(this);
    this.checkPromoCode = this.checkPromoCode.bind(this);
    this.showAddCard = this.showAddCard.bind(this);
  }

  componentWillReceiveProps({ customer }) {
    const newAccount = !(customer && customer.firstname);
    this.setState({ newAccount });
  }

  validateCardNumber(cardNumber) {
    const strippedCardNumber = cardNumber.replace(/ /g, '');
    if (!isCardTypeValid(strippedCardNumber)) {
      this.setState({ cardError: 'les cartes American Express (Amex) ne sont pas supportées' });
    } else if (strippedCardNumber && strippedCardNumber.length > 15 && !isCardNumberValid(strippedCardNumber)) {
      this.setState({ cardError: 'Oups une erreur s\'est glissé. Êtes-vous sûr du numéro ?' });
    } else {
      this.setState({ cardError: null });
    }
  }

  validateEmail(email) {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmailValid = re.test(email);
    this.setState({ emailError: !isEmailValid });
    if (isEmailValid) {
      trackUserEmail(email);
    }
  }

  inputChange(field, value) {
    if (field === 'zipcode') {
      this.setState({
        zipcodeError: checkZipcodeError(value),
        zipcodeLocality: getLocality(value),
        locality: getLocality(value) || '',
      });
      const deliveryFee = getDeliveryFees(value);
      if (typeof deliveryFee === 'number') {
        this.props.setDeliveryFee(deliveryFee);
      }
    } else if (field === 'passwordCheck') {
      this.setState({ passwordCheckError: value !== this.state.password });
    } else if (field === 'cardNumber') {
      this.validateCardNumber(value);
    } else if (field === 'email') {
      this.validateEmail(value);
    }
    this.setState({ [field]: value });
  }

  submitRegisterAndOrder() {
    const { firstname, lastname, password, email, phone, street, zipcode, locality, comment, expiryDate, cvc,
      cardNumber, cardId, seller, deliveryFee } = this.state;
    const address = { street, zipcode, locality, comment };
    const customer = { firstname, lastname, password, email, phone, address, deliveryFee };
    const card = { expiryDate, cvc, cardNumber, cardId };
    this.props.submitRegisterAndOrder(customer, card, seller);
  }

  submitLoggedInOrder() {
    const { street, zipcode, locality, comment, expiryDate, cvc, cardNumber, cardId, phone,
      seller, deliveryFee } = this.state;
    const customer = { phone, address: { street, zipcode, locality, comment }, deliveryFee };
    const card = { expiryDate, cvc, cardNumber, cardId };
    this.props.submitLoggedInOrder(customer, card, seller);
  }

  isValid() {
    const fields = ['firstname', 'lastname', 'email', 'phone', 'street', 'zipcode', 'locality'];
    const passwordSelected = this.state.password
      || !this.state.newAccount
      || featureSwitch('step6_allowShadowAccount');

    const cardInputValid = (this.state.expiryDate && this.state.cvc && this.state.cardNumber
      && isCardTypeValid(this.state.cardNumber) && isCardNumberValid(this.state.cardNumber));

    const { fbId, cards = [] } = this.props.customer;
    const cardSelected = cardInputValid
      || this.state.cardId
      || featureSwitch('step6_allowOrderWithoutCard')
      || featureSwitch('infos_hideCardBlock')
      || (this.state.newAccount &&
        featureSwitch('infos_hideCardBlockOnlyWhenNewCustomer'))
      || (fbId && cards.length === 0)
        ;

    const emptyFields = fields.filter(field => !this.state[field]);
    return emptyFields.length === 0 && !this.state.passwordCheckError && !this.state.zipcodeError && cardSelected
      && passwordSelected;
  }

  submit() {
    if (!this.isValid()) {
      return this.setState({ formError: true });
    }
    return this.state.newAccount ? this.submitRegisterAndOrder() : this.submitLoggedInOrder();
  }

  checkPromoCode(promoCode) {
    if (!promoCode) return;
    this.props.checkPromoCode(promoCode);
  }

  showAddCard() {
    this.setState({ showCreditCard: true });
    this.inputChange('cardId', null);
  }

  renderCreditCard() {
    return (<CreditCard
      onChange={this.inputChange}
      formError={this.state.formError}
      error={this.state.cardError}
      cardNumber={this.state.cardNumber}
      expiryDate={this.state.expiryDate}
      cvc={this.state.cvc}
    />);
  }

  renderForm() {
    const showPassword = this.state.newAccount && !featureSwitch('step6_allowShadowAccount');
    const { fbId, cards = [] } = this.props.customer;
    const hideCardBlock = featureSwitch('infos_hideCardBlock') || (this.state.newAccount &&
      featureSwitch('infos_hideCardBlockOnlyWhenNewCustomer')) || (fbId && cards.length === 0);
    return (
      <div>
        <Form1 marginTop="0px">
          {featureSwitch('order_thirdPersonLabels') ?
            <Title4Top>Vendeur</Title4Top> : null}
          {featureSwitch('order_thirdPersonLabels') ?
            <InputRow>
              <Input
                name="seller"
                type="text"
                placeholder={'Nom du vendeur - boutique'}
                value={this.state.seller}
                onChange={e => this.inputChange('seller', e.target.value)}
              />
            </InputRow> : null}

          <Title4Top>{featureSwitch('order_thirdPersonLabels') ?
            'Les coordonnées de votre client' : 'Mes coordonnées'}
          </Title4Top>
          <InputRow>
            <Input
              name="firstname"
              disabled={!this.state.newAccount}
              half type="text"
              placeholder={featureSwitch('order_thirdPersonLabels') ? 'Le prénom de votre client' : 'Votre prénom'}
              value={this.state.firstname}
              onChange={e => this.inputChange('firstname', e.target.value)}
              error={this.state.formError && !this.state.firstname}
            />
            <Input
              name="lastname"
              disabled={!this.state.newAccount}
              half type="text"
              placeholder={featureSwitch('order_thirdPersonLabels') ? 'Le nom de votre client' : 'Votre nom'}
              value={this.state.lastname}
              onChange={e => this.inputChange('lastname', e.target.value)}
              error={this.state.formError && !this.state.lastname}
            />
          </InputRow>
          <InputRow>
            <Input
              name="email"
              disabled={!this.state.newAccount}
              half type="email" placeholder="Adresse email" value={this.state.email}
              onChange={e => this.inputChange('email', e.target.value)}
              onBlur={() => this.setState({ showEmailError: true })}
              error={this.state.formError && !this.state.email}
            />
            <Input
              name="phone"
              half type="tel" placeholder="Téléphone" value={this.state.phone}
              onChange={e => this.inputChange('phone', e.target.value)}
              error={this.state.formError && !this.state.phone}
            />
          </InputRow>
          {this.state.emailError && this.state.showEmailError &&
            <ErrorText>Merci de vérifier votre email</ErrorText>}
          {showPassword &&
            <Input
              name="password"
              type="password" placeholder="Votre mot de passe"
              onChange={e => this.inputChange('password', e.target.value)}
              value={this.state.password}
              error={this.state.formError && !this.state.password}
            />}
          {showPassword &&
            <Input
              name="passwordCheck"
              type="password" placeholder="Confirmer votre mot de passe"
              onChange={e => this.inputChange('passwordCheck', e.target.value)}
              value={this.state.passwordCheck}
            />}
          {this.state.passwordCheckError && <ErrorText>Les 2 mots de passe ne correspondent pas</ErrorText>}

          <Title4Top>{featureSwitch('order_thirdPersonLabels') ? 'L‘adresse de votre client' : 'Mon adresse'}
          </Title4Top>
          <Input
            name="street"
            type="text" placeholder="Rue et numéro de rue" value={this.state.street}
            onChange={e => this.inputChange('street', e.target.value)}
            error={this.state.formError && !this.state.street}
          />
          <InputRow>
            <Input
              name="zipcode"
              half type="text" placeholder="Code postal" value={this.state.zipcode}
              onChange={e => this.inputChange('zipcode', e.target.value)}
              error={this.state.formError && !this.state.zipcode}
            />
            <Input
              name="locality"
              disabled={this.state.zipcodeLocality}
              half type="text" placeholder="Ville" value={this.state.locality}
              onChange={e => this.inputChange('locality', e.target.value)}
              error={this.state.formError && !this.state.locality}
            />
          </InputRow>
          {this.state.zipcodeError}
          <Input
            name="comment"
            type="text" placeholder="Complément (digicode, étage, etc)" value={this.state.comment}
            onChange={e => this.inputChange('comment', e.target.value)}
            autoFill="address-line2"
          />
        </Form1>

        {!featureSwitch('infos_hideOrderSummary') &&
          <OrderSummary
            slots={this.props.slots}
            total={this.props.total}
            totalPaid={this.props.totalPaid}
            totalPaidBrand={this.props.totalPaidBrand}
            deliveryFee={this.props.deliveryFee}
            minOrderAmount={this.props.minOrderAmount}
            minOrderAmountFee={this.props.minOrderAmountFee}
            promoCode={this.props.promoCode}
            addPromoCode={this.checkPromoCode}
            removePromoCode={this.props.removePromoCode}
          >
            {featureSwitch('step6_showPromoCodeField') &&
              <PromoCodeField
                checkPromoCode={this.checkPromoCode}
                promoCode={this.props.promoCode}
                errorPromoCode={this.props.errorPromoCode}
              />
            }
          </OrderSummary>
        }

        {!hideCardBlock &&
          <div>
            <Title4Top>
              {featureSwitch('order_thirdPersonLabels') ?
                'Le moyen de paiement de votre client' : 'Votre moyen de paiement'}
            </Title4Top>
            <P>
              <Highlight>
                {featureSwitch('order_thirdPersonLabels') ? 'Votre client sera ' : 'Vous serez '}
                débité du montant exact</Highlight>
              {featureSwitch('order_thirdPersonLabels') ?
                ' de la commande à la suite du premier RDV avec le couturier.' :
                ' de votre commande à la suite du premier RDV avec le couturier.'}
            </P>
            <Center>
              <CardIcon src={VisaIcon} />
              <CardIcon src={MasterCardIcon} />
            </Center>
            {this.state.newAccount ?
              this.renderCreditCard()
              : <div>
                {(this.state.showCreditCard || !this.props.customer.cards || this.props.customer.cards.length === 0) ?
                  this.renderCreditCard()
                  : <div>
                    {this.props.customer.cards && this.props.customer.cards.map(card =>
                      <Choice
                        isSelected={this.state.cardId === card.id}
                        key={card.id}
                        onClick={() => this.inputChange('cardId', card.id)}
                      >
                        {card.alias} - {card.expirationDate.slice(0, 2)} / {card.expirationDate.slice(2, 4)}
                      </Choice>,
                    )}
                    <Choice onClick={this.showAddCard}>
                      Ajouter une nouvelle carte bancaire
                    </Choice>
                  </div>
                }
              </div>
            }
          </div>
        }

        {this.state.formError && <ErrorText>Merci de remplir tous les champs</ErrorText>}
        <OrderFooter
          isValid={!!this.isValid()}
          submit={this.submit}
        />
      </div>
    );
  }

  render() {
    if (this.props.hide) {
      return (<div />);
    }
    return this.renderForm();
  }
}

InfosBlock.propTypes = {
  total: PropTypes.number.isRequired,
  totalPaid: PropTypes.number.isRequired,
  totalPaidBrand: PropTypes.number.isRequired,
  deliveryFee: PropTypes.number.isRequired,
  setDeliveryFee: PropTypes.func.isRequired,
  minOrderAmount: PropTypes.number.isRequired,
  minOrderAmountFee: PropTypes.number.isRequired,
  submitRegisterAndOrder: PropTypes.func.isRequired,
  submitLoggedInOrder: PropTypes.func.isRequired,
  checkPromoCode: PropTypes.func.isRequired,
  removePromoCode: PropTypes.func.isRequired,
  customer: PropTypes.shape({
    firstname: PropTypes.string,
    fbId: PropTypes.string,
    cards: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  errorPromoCode: PropTypes.bool.isRequired,
  promoCode: PropTypes.shape({
    value: PropTypes.number,
    unit: PropTypes.string,
  }).isRequired,
  hide: PropTypes.bool.isRequired,
  slots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default InfosBlock;
