export const zones = [
  {
    id: 'PARIS',
    name: 'Paris',
    cornerNW: { latitude: 48.93651176701517, longitude: 2.151506593360409 },
    cornerSE: { latitude: 48.794001357725215, longitude: 2.500322511329159 },
    deliveryFee: 5,
    zipcodes: {
      75001: {},
      75002: {},
      75003: {},
      75004: {},
      75005: {},
      75006: {},
      75007: {},
      75008: {},
      75009: {},
      75010: {},
      75011: {},
      75012: {},
      75013: {},
      75014: {},
      75015: {},
      75016: {},
      75017: {},
      75018: {},
      75019: {},
      75020: {},
      75116: {}, // 2nd zipcode for Paris 16
      92000: { deliveryFee: 15 }, // Nanterre
      92100: {}, // Boulogne-Billancourt
      92110: {}, // Clichy
      92120: {}, // Montrouge
      92130: {}, // Issy
      92140: { deliveryFee: 15 }, // Clamart
      92150: { deliveryFee: 15 }, // Suresnes
      92170: {}, // Vanves (1)
      92190: { deliveryFee: 15 }, // Meudon (1)
      92200: {}, // Neuilly
      92210: { deliveryFee: 15 }, // St Cloud
      92240: {}, // Malakoff + Vanves (2)
      92250: { deliveryFee: 15 }, // La Garenne Colombes
      92270: { deliveryFee: 15 }, // Bois Colombes
      92300: {}, // Levallois
      92320: {}, // Chatillon
      92360: { deliveryFee: 15 }, // Meudon (2)
      92380: { deliveryFee: 15 }, // Garches
      92400: { deliveryFee: 15 }, // Courbevoie
      92410: { deliveryFee: 15 }, // Ville d'Avray
      92420: { deliveryFee: 15 }, // Vaucresson
      92430: { deliveryFee: 15 }, // Marnes la Coquette
      92500: { deliveryFee: 15 }, // Rueil-malmaison
      92600: { deliveryFee: 15 }, // Asnières
      92800: { deliveryFee: 15 }, // Puteaux
      93100: {}, // Montreuil
      93260: {}, // Les lilas
      93310: {}, // Le Pré-Saint-Gervais
      94160: {}, // Saint Mandé
      94200: {}, // Ivry-sur-Seine
      94205: {}, // Ivry-sur-Seine
      94220: {}, // Charenton le pont
      94250: {}, // Kremlin-Bicetre (1) + Gentilly
      94270: {}, // Kremlin-Bicetre (2)
      94300: {}, // Vincennes
      94400: {}, // Vitry sur Seine
      94410: {}, // Maison Alfort (1)
      94700: {}, // Maison Alfort (2)
      94800: {}, // Villejuif
    },
  },
  {
    id: 'MARSEILLE',
    name: 'Marseille',
    deliveryFee: 5,
    cornerNW: { latitude: 43.36275505192238, longitude: 5.334924519423453 },
    cornerSE: { latitude: 43.19760956070986, longitude: 5.479812310247439 },
    zipcodes: {
      // Marseille except the 3rd, 14th, 15th and 16th arrondissements
      13001: {},
      13002: {},
      13004: {},
      13005: {},
      13006: {},
      13007: {},
      13008: {},
      13009: {},
      13010: {},
      13011: {},
      13012: {},
      13013: {},
    },
  },
  {
    id: 'LYON',
    name: 'Lyon',
    deliveryFee: 5,
    cornerNW: { latitude: 45.803251, longitude: 4.776678 },
    cornerSE: { latitude: 45.698921, longitude: 4.902279 },
    zipcodes: {
      69001: {},
      69002: {},
      69003: {},
      69004: {},
      69005: {},
      69006: {},
      69007: {},
      69008: {},
      69009: {},
    },
  },
  {
    id: 'SEINE_ET_MARNE',
    name: 'Seine-et-Marne',
    deliveryFee: 5,
    cornerNW: { latitude: 48.83180441791672, longitude: 2.4954817571582453 },
    cornerSE: { latitude: 48.78878953365783, longitude: 2.6187558743142745 },
    zipcodes: {
      77090: {}, // Collégien
      77111: {}, // Soignolles-en-Brie + Solers
      77135: {}, // Pontcarré
      77144: {}, // Chalifert + Montévrain
      77150: {}, // Férolles-Attilly + Lésigny
      77163: {}, // Dammartin-sur-Tigeaux + Mortcerf + Tigeaux
      77164: {}, // Ferrières-en-Brie
      77166: {}, // Évry-Grégy-sur-Yerre + Grisy-Suisnes
      77170: {}, // Brie-Comte-Robert + Servon + Coubert
      77173: {}, // Chevry-Cossigny
      77174: {}, // Villeneuve-le-Comte + Villeneuve-Saint-Denis
      77177: {}, // Brou-sur-Chantereine
      77181: {}, // Courtry + Le Pin
      77183: {}, // Croissy-Beaubourg
      77184: {}, // Émerainville
      77185: {}, // Lognes
      77186: {}, // Noisiel
      77200: {}, // Torcy
      77220: {}, // Favières + Gretz-Armainvilliers + Liverdy-en-Brie + Presles-en-Brie + Tournan-en-Brie
      77330: {}, // Ozoir-la-Ferrière
      77340: {}, // Pontault-Combault
      77360: {}, // Vaires-sur-Marne
      77400: {}, // Carnetin + Dampmart + Gouvernes + Lagny-sur-Marne + Pomponne +
      // Saint-Thibault-des-Vignes + Thorigny-sur-Marne
      77410: {}, // Annet-sur-Marne + Charmentray + Charny + Claye-Souilly +
      // Fresnes-sur-Marne + Gressy + Messy + Précy-sur-Marne + Saint-Mesmes + Villeroy + Villevaudé
      77420: {}, // Champs-sur-Marne
      77500: {}, // Chelles
      77600: {}, // Bussy-Saint-Martin + Chanteloup-en-Brie + Conches-sur-Gondoire +
      // Guermantes + Jossigny + Bussy-Saint-Georges
      77610: {}, // Les Chapelles-Bourbon + Châtres + Crèvecœur-en-Brie
      // Fontenay-Trésigny + La Houssaye-en-Brie + Marles-en-Brie + Neufmoutiers-en-Brie
      77680: {}, // Roissy-en-Brie
      77700: {}, // Bailly-Romainvilliers + Chessy + Coupvray + Magny-le-Hongre + Serris
      93160: {}, // Noisy-le-Grand
      94350: {}, // Villiers-sur-Marne
      94360: {}, // Bry-sur-Marne
      94420: {}, // Le Plessis-Trévise
      94430: {}, // Chennevières-sur-Marne
      94440: {}, // Santeny + Marolles-en-Brie
      94490: {}, // Ormesson-sur-Marne
      94500: {}, // Champigny-sur-Marne
      94510: {}, // La Queue-en-Brie
      94520: {}, // Mandres-les-Roses + Périgny
      94880: {}, // Noiseau
    },
  },
  {
    id: 'AIX_EN_PROVENCE',
    name: 'Aix-en-Provence',
    deliveryFee: 5,
    cornerNW: { latitude: 43.62037483233502, longitude: 5.295963901626578 },
    cornerSE: { latitude: 43.456147, longitude: 5.504146 },
    zipcodes: {
      13080: {}, // Aix
      13090: {}, // Aix
      13100: {}, // Aix + le Tholonet
      13320: {}, // Bouc bel air
      13290: {}, // Aix + les Milles
      13540: {}, // Aix + Puyricard
    },
  },
  {
    id: 'METZ',
    name: 'Metz',
    deliveryFee: 10,
    cornerNW: { latitude: 49.157096, longitude: 6.107081 },
    cornerSE: { latitude: 49.059383, longitude: 6.275795 },
    zipcodes: {
      57000: {}, // Metz
      57050: {}, // Metz, Plappeville, Vantoux, Ban St Martin, Longeville,
      57070: {}, // Metz, St Julien, La Grange aux bois
      57140: {}, // Woippy, la Maxe
      57151: {}, // Marly
      57158: {}, // Montigny (2)
      57160: {}, // Scy-Chazelles, Moulins,
      57245: {}, // Peltre
      57280: {}, // Semécourt
      57950: {}, // Montigny
    },
  },
  {
    id: 'BORDEAUX',
    name: 'Bordeaux',
    deliveryFee: 5,
    cornerNW: { latitude: 44.919193, longitude: -0.687469 },
    cornerSE: { latitude: 44.795899, longitude: -0.512567 },
    zipcodes: {
      33000: {}, // Bordeaux
      33100: {}, // Bordeaux
      33200: {}, // Bordeaux
      33300: {}, // Bordeaux
      33800: {}, // Bordeaux
    },
  },
];

export const validZipcodes = zones
  .reduce((currentZipcodes, zone) => currentZipcodes.concat(Object.keys(zone.zipcodes)), []);

export const getZone = zipcode => zones.find(zone =>
  Object.keys(zone.zipcodes).includes(zipcode));

export const getDeliveryFees = (zipcode) => {
  const zone = getZone(zipcode);
  if (!zone) return undefined;
  const zipcodeDeliveryFee = zone.zipcodes[zipcode].deliveryFee;
  if (typeof zipcodeDeliveryFee === 'number') {
    return zipcodeDeliveryFee;
  }
  return getZone(zipcode).deliveryFee;
};

export function isInZone({ latitude, longitude }, zone) {
  const { cornerNW, cornerSE } = zone;
  return cornerNW.latitude >= latitude && latitude >= cornerSE.latitude &&
    cornerNW.longitude <= longitude && longitude <= cornerSE.longitude;
}

export function checkPosition({ latitude, longitude }, zonesToCheck = zones) {
  return zonesToCheck
    .reduce((response, zone) => response || isInZone({ latitude, longitude }, zone), false);
}

export default function checkError(zipcode) {
  if (typeof zipcode !== 'string' || zipcode.length !== 5) {
    return {
      message: 'Merci d\'entrer un code postal à 5 chiffres',
      code: 1,
    };
  }
  if (!validZipcodes.includes(zipcode)) {
    return {
      message: 'Votre ville n\'est pas encore couverte par notre service. Voici la liste des villes couvertes pour le moment : Paris et une partie de l‘Ile de France, Marseille, Lyon, Bordeaux et Aix-en-Provence.', // eslint-disable-line max-len
      code: 2,
    };
  }
  return {
    message: '',
    code: 0,
  };
}
