import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import styled from 'styled-components';

import ProgressBar from './order/ProgressBar';
import InfosBlock from '../components/InfosBlock';
import { Wrapper, Block, Button, Header2, Logo, Center, P, colors, mobileThresholdPixels }
  from '../components/styledComponents';
import LogoImg from '../assets/logo1-128.png';
import { trackEvent } from '../services/analytics';
import { errorMessages } from '../services/mangopay';

const Message = styled.h2`
  font-weight: 200;
  font-size: 26px;
  margin-bottom: 20px;
  margin-top: 20px;
  color: ${colors.navy};
  text-align: center;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 3px;
    margin-top: 5px;
    font-size: 20px;
  }
`;

const ErrorMessage = styled.div`
  text-align: center;
`;

const renderRegisterSuccess = () => (
  <div>
    <Message>Votre inscription et votre commande ont bien été prises en compte</Message>
    <Center>
      <Link to={'/'}><Button>{"Revenir vers la page d'accueil"}</Button></Link>
    </Center>
  </div>
);

const renderLoggedInSuccess = () => (
  <div>
    <Message>Votre commande a bien été prise en compte</Message>
    <Center>
      <Link to={'/'}><Button>{"Revenir vers la page d'accueil"}</Button></Link>
    </Center>
  </div>
);

const renderError = (hideError, errorType) => {
  const message = errorMessages[errorType] || 'Une erreur a eu lieu';
  return (
    <ErrorMessage>
      <P>{message}</P>
      <Button onClick={hideError}>Cliquer pour réessayer</Button>
    </ErrorMessage>
  );
};

const Step6 = ({ submitRegisterAndOrder, isLoading, successRegisterOrder, error, total, customer,
  submitLoggedInOrder, successLoggedInOrder, slots, checkPromoCode, isPromoCodeLoading, errorPromoCode,
  promoCode, totalPaid, deliveryFee, minOrderAmountFee, minOrderAmount, hideError, errorType,
  setDeliveryFee, totalPaidBrand, removePromoCode,
}) => (
  <Wrapper>
    <Block id="infos">
      <Header2>
        <Link
          to={'/'} style={{ flex: 1, textAlign: 'center' }}
          onClick={() => trackEvent('click', 'home_step6')}
        >
          <Logo src={LogoImg} />
        </Link>
        <ProgressBar step={4} />
      </Header2>
      {isLoading && <Center><P>Chargement</P></Center>}
      {successLoggedInOrder && renderLoggedInSuccess()}
      {successRegisterOrder && renderRegisterSuccess()}
      {error && renderError(hideError, errorType)}
      <InfosBlock
        checkPromoCode={checkPromoCode}
        removePromoCode={removePromoCode}
        customer={customer}
        errorPromoCode={errorPromoCode}
        isPromoCodeLoading={isPromoCodeLoading}
        promoCode={promoCode}
        slots={slots}
        submitLoggedInOrder={submitLoggedInOrder}
        submitRegisterAndOrder={submitRegisterAndOrder}
        total={total}
        totalPaid={totalPaid}
        totalPaidBrand={totalPaidBrand}
        deliveryFee={deliveryFee}
        setDeliveryFee={setDeliveryFee}
        minOrderAmountFee={minOrderAmountFee}
        minOrderAmount={minOrderAmount}
        hide={successRegisterOrder || successLoggedInOrder || isLoading || error}
      />
    </Block>
  </Wrapper>
);

Step6.propTypes = {
  checkPromoCode: PropTypes.func.isRequired,
  removePromoCode: PropTypes.func.isRequired,
  customer: PropTypes.shape({}),
  error: PropTypes.bool,
  errorType: PropTypes.string,
  errorPromoCode: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPromoCodeLoading: PropTypes.bool,
  hideError: PropTypes.func.isRequired,
  promoCode: PropTypes.shape({}),
  submitLoggedInOrder: PropTypes.func.isRequired,
  submitRegisterAndOrder: PropTypes.func.isRequired,
  successLoggedInOrder: PropTypes.bool,
  successRegisterOrder: PropTypes.bool,
  total: PropTypes.number.isRequired,
  totalPaid: PropTypes.number.isRequired,
  totalPaidBrand: PropTypes.number.isRequired,
  deliveryFee: PropTypes.number.isRequired,
  setDeliveryFee: PropTypes.func.isRequired,
  minOrderAmountFee: PropTypes.number.isRequired,
  minOrderAmount: PropTypes.number.isRequired,
  slots: PropTypes.arrayOf(PropTypes.shape({})),
};

Step6.defaultProps = {
  isLoading: false,
  successRegisterOrder: false,
  successLoggedInOrder: false,
  error: false,
  errorType: null,
  errorPromoCode: false,
  isPromoCodeLoading: false,
  customer: {},
  promoCode: {},
  slots: [],
};

export default Step6;
