// simple alterations list for alterations_showSimpleAsOffered feature
const simpleAlterations = [
  '6.mant-rac-2',
  '6.mant-ral-2',
  '6.haut-rac-2',
  '6.haut-rac-5',
  '6.haut-ral-2',
  '6.haut-ral-5',
  '6.pant-rac-1',
  '6.pant-rac-2',
  '6.pant-rac-3',
  '6.pant-rac-4',
  '6.jupe-rac-1',
  '6.jupe-rac-2',
  '6.jupe-rac-3',
  '6.jupe-rac-4',
  '6.jupe-rac-5',
  '6.jupe-rac-6',
  '6.robe-rac-1',
  '6.robe-rac-2',
  '6.robe-rac-3',
  '6.robe-rac-4',
  '6.robe-rac-5',
  '6.robe-rac-6',
  '6.robe-rac-7',
  '6.robe-rac-8',
  '6.robe-rac-9',
  '6.robe-rac-10',
  '6.robe-rac-11',
  '6.robe-rac-12',
  '6.robe-ral-1',
  '6.robe-ral-2',
  '6.robe-ral-3',
  '6.robe-ral-4',
  '6.robe-ral-5',
  '6.robe-ral-6',
  '6.robe-ral-7',
  '6.robe-ral-8',
  '6.robe-ral-9',
  '6.robe-ral-10',
  '6.robe-ral-11',
  '6.robe-ral-12',
  '6.pull-rac-2',
  '6.pull-rac-5',
  '6.pull-ral-2',
  '6.pull-ral-5',
];

export default simpleAlterations;
